/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import history from "../../components/History";
import { getRecordsPerPage } from "../../constants/Pagination";
import { formatDateWithTime } from "../../helpers/FormatDate";
import Application from "./Application";
import ReassignAgencyModal from "./ReassignAgencyModal";
import EditContactModal from "./EditContactModal";

const componentStyle = css`
  td.check {
    border-top: none !important;
  }

  i {
    cursor: pointer;
    width: 20px;
    text-align: center;
  }
`;
class Account extends React.Component {
  state = {
    inviteText: "Resend Invite",
    applicationsOpen: false,
    showEditContactModal: false,
    selectedContact: {},
    showReassignAgencyModal: false,
    selectedApplicationIds: [],
  };

  toggleApplicationsOpen = () => {
    this.setState({
      applicationsOpen: this.state.applicationsOpen ? false : true,
    });
  };

  editAccount = async () => {
    const { account, accountsContainer } = this.props;
    await accountsContainer.getAccount(account.id);
    history.push("/agencies-and-contacts/contact");
  };

  deleteAccount = async () => {
    const { account, brokerCompaniesContainer } = this.props;
    let confirm = window.confirm(
      "Are you sure you want to delete " + account.name + "?"
    );
    if (confirm) {
      await brokerCompaniesContainer.deleteAccounts([account.id]);
      this.updateContactList();
    }
  };

  updateContactList = async () => {
    const { brokerCompaniesContainer, searchCriteria } = this.props;
    await brokerCompaniesContainer.searchBrokerCompanies(
      searchCriteria.searchTerm,
      searchCriteria.searchIn,
      searchCriteria.currentPage,
      getRecordsPerPage(),
      searchCriteria.orderBy,
      searchCriteria.sort[searchCriteria.orderBy] === "desc" ? true : false
    );
  };

  markAccountForDeletion = (id, e) => {
    const { accountsContainer } = this.props;
    accountsContainer.markAccountForDeletion(id, e.currentTarget.checked);
  };

  resendInvite = async () => {
    const { account, accountsContainer } = this.props;

    await accountsContainer.completeRegistration(account.email);

    this.setState({
      inviteText: "Invite Sent",
    });
  };

  handleSwitchAgency = async (account) => {
    if (account.applications?.length > 0) {
      window.alert(
        "Please reassign all applications for this contact before moving to another agency."
      );
    } else {
      const { accountsContainer } = this.props;
      await accountsContainer.getAccount(account.id);
      this.setState({
        showEditContactModal: true,
        selectedContact: account,
      });
    }
  };

  handleReassignAgencyModalClose = async (isAgencySwitched) => {
    this.setState({
      showReassignAgencyModal: false,
    });
    if (isAgencySwitched) {
      await this.updateContactList();
    }
  };

  handleEditContactModalClose = (isEdited) => {
    this.setState({
      showEditContactModal: false,
      showReassignAgencyModal: isEdited,
    });
  };

  handleCheckboxChange = (id, isChecked) => {
    this.setState((prevState) => {
      const selectedApplicationIds = isChecked
        ? [...prevState.selectedApplicationIds, id]
        : prevState.selectedApplicationIds.filter(
            (selectedId) => selectedId !== id
          );

      return { selectedApplicationIds };
    });
  };

  handleButtonClick = () => {
    if (this.childRef) {
      this.childRef.handleUnselectId();
      this.childRef.handleReassignModalShow();
    }
  };

  unselectApplication = () => {
    this.setState({
      selectedApplicationIds: [],
    });
  };
  render() {
    const {
      account,
      accountsContainer,
      brokerCompaniesContainer,
      applicationsContainer,
      searchCriteria,
      brokerCompanyId,
    } = this.props;

    return (
      <>
        <EditContactModal
          show={this.state.showEditContactModal}
          onHide={this.handleEditContactModalClose}
          accountsContainer={accountsContainer}
        />

        <ReassignAgencyModal
          show={this.state.showReassignAgencyModal}
          onHide={this.handleReassignAgencyModalClose}
          accountsContainer={accountsContainer}
          agencies={brokerCompaniesContainer.state.brokerCompanies}
          agencyId={brokerCompanyId}
          selectedContact={this.state.selectedContact}
        />

        <tr css={componentStyle}>
        <td colSpan="3"></td>
        <td
            rowSpan={this.state.applicationsOpen ? account.applications.length + 1 : ""}
          >
            <i
              className={
                "fa " +
                (this.state.applicationsOpen
                  ? "fa-chevron-down"
                  : "fa-chevron-right")
              }
              onClick={this.toggleApplicationsOpen}
            />
          </td>
          <td className="check">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={(e) => {
                  this.markAccountForDeletion(account.id, e);
                }}
              />
            </div>
          </td>
          <td>
            {account.firstName} {account.lastName}
          </td>
          <td>{account.email}</td>
          <td>{account.admittedProducerCode}</td>
          <td>
            {account.lastLoginDateTimeUtc
              ? formatDateWithTime(account.lastLoginDateTimeUtc)
              : ""}
          </td>
          <td className="action">
            <i className="fa fa-pencil-square-o" onClick={this.editAccount} />
          </td>
          <td className="action">
            <i
              className="fa fa-trash"
              onClick={() => {
                this.deleteAccount(account.name, account.id);
              }}
            />
          </td>
          <td>
            <button className="anchor" onClick={this.resendInvite}>
              {this.state.inviteText}
            </button>
          </td>
          <td style={{ textAlign: "center" }}>
            <i
              className="fa fa-share"
              onClick={() => {
                this.handleSwitchAgency(account);
              }}
            />
          </td>
          <td colSpan="2"></td>

        </tr>
        {this.state.applicationsOpen && (
          <>
            {this.state.selectedApplicationIds.length > 0 && (
              <>              
              <td colSpan="5"></td>
              <button
                className="btn btn-sm btn-primary search"
                onClick={this.handleButtonClick}
                style={{ textAlign: "right" }}
              >
                Reassign Selected
              </button>
              </>
            )}
            {account.applications.map((application, index) => (
              <Application
                key={index}
                application={application}
                accountsContainer={accountsContainer}
                brokerCompaniesContainer={brokerCompaniesContainer}
                applicationsContainer={applicationsContainer}
                searchCriteria={searchCriteria}
                updateContactList={this.updateContactList}
                onCheckboxChange={this.handleCheckboxChange}
                selectedApplicationIds={this.state.selectedApplicationIds}
                unselectApplication={this.unselectApplication}
                ref={(ref) => (this.childRef = ref)}
                brokerCompanyId={brokerCompanyId}
                contact={account}
              />
            ))}
          </>
        )}
      </>
    );
  }
}

export default Account;
