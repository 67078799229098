/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import history from "../../components/History";
import { getRecordsPerPage } from "../../constants/Pagination";
import Account from "./Account";

const componentStyle = css`
  i {
    cursor: pointer;
    width: 20px;
    text-align: center;
  }
`;
class Agency extends React.Component {
  state = {
    accountsOpen: false,
  };

  toggleAccountsOpen = () => {
    this.setState({
      accountsOpen: this.state.accountsOpen ? false : true,
    });
  };

  editAgency = async () => {
    const { brokerCompaniesContainer, brokerCompany } = this.props;
    await brokerCompaniesContainer.getBrokerCompany(brokerCompany.id);
    history.push("/agencies-and-contacts/agency");
  };

  deleteAgency = async (name, id) => {
    const { brokerCompaniesContainer, searchCriteria } = this.props;

    let confirm = window.confirm(
      "Are you sure you want to delete " + name + " and it's contacts?"
    );
    if (confirm) {
      await brokerCompaniesContainer.deleteBrokerCompanies([id]);
      await brokerCompaniesContainer.searchBrokerCompanies(
        searchCriteria.searchTerm,
        searchCriteria.currentPage,
        getRecordsPerPage(),
        searchCriteria.orderBy,
        searchCriteria.sort[searchCriteria.orderBy] === "desc" ? true : false
      );
    }
  };

  markBrokerCompanyForDeletion = (id, e) => {
    const { brokerCompaniesContainer } = this.props;
    brokerCompaniesContainer.markBrokerCompanyForDeletion(
      id,
      e.currentTarget.checked
    );
  };

  render() {
    const {
      brokerCompany,
      accountsContainer,
      brokerCompaniesContainer,
      searchCriteria,
      applicationsContainer,
    } = this.props;
    return (
      <>
        <tr css={componentStyle}>
          <td
            rowSpan={
              this.state.accountsOpen
                ? brokerCompany.contacts?.length ||
                  0 + 1 + brokerCompany.contacts?.applications?.length ||
                  0 + 1
                : ""
            }
          >
            <i
              className={
                "fa " +
                (this.state.accountsOpen
                  ? "fa-chevron-down"
                  : "fa-chevron-right")
              }
              onClick={this.toggleAccountsOpen}
            />
          </td>
          <td>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={(e) => {
                  this.markBrokerCompanyForDeletion(brokerCompany.id, e);
                }}
              />
            </div>
          </td>
          <td
            rowSpan={
              this.state.accountsOpen
                ? brokerCompany.contacts?.length ||
                  0 + 1 + brokerCompany.contacts?.applications?.length ||
                  0 + 1
                : ""
            }
          >
            {brokerCompany.name}
          </td>
          <td colSpan="6"></td>
          <td style={{ textAlign: "center" }}>
            <i className="fa fa-pencil-square-o" onClick={this.editAgency} />
          </td>
          <td style={{ textAlign: "center" }}>
            <i
              className="fa fa-trash"
              onClick={() => {
                this.deleteAgency(brokerCompany.name, brokerCompany.id);
              }}
            />
          </td>
          <td colSpan="4"></td>
        </tr>

        {this.state.accountsOpen && (
          <>
            {brokerCompany?.contacts?.map((account, index) => (
              <Account
                key={index}
                account={account}
                accountsContainer={accountsContainer}
                brokerCompaniesContainer={brokerCompaniesContainer}
                applicationsContainer={applicationsContainer}
                searchCriteria={searchCriteria}
                brokerCompanyId={brokerCompany.id}
              />
            ))}
          </>
        )}
      </>
    );
  }
}

export default Agency;
