import { sendPost, sendGet, sendDelete } from "./generic";

export const getBrokerCompaniesList = () => {
  return sendGet("BrokerCompanies/List");
};

export const getBrokerCompany = (id) => {
  return sendGet("BrokerCompanies/" + id);
};

export const addBrokerCompany = (payload) => {
  return sendPost("BrokerCompanies", payload);
};

export const updateBrokerCompany = (id, payload) => {
  return sendPost("BrokerCompanies/" + id, payload);
};

export const deleteBrokerCompanies = (payload) => {
  return sendDelete("BrokerCompanies", payload);
};

export const searchBrokerCompanies = (
  searchTerm,
  searchIn,
  pageIndex,
  numberOfRecords,
  orderBy,
  parentDescending,
  childDescending = false
) => {
  return sendGet(
    "BrokerCompanies/" +
      searchTerm +
      "/" +
      searchIn +
      "/" +
      pageIndex +
      "/" +
      numberOfRecords +
      "/" +
      orderBy +
      "/" +
      parentDescending +
      "/" +
      childDescending
  );
};

export const exportBrokerCompanies = () => {
  return sendGet("BrokerCompanies/Export");
};
