/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Modal, Form } from "react-bootstrap";
import * as api from "../../api";

class ReassignUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUserId: "",
      selectedAgencyId: "",
      agencyUsers: [],
      agencyValidationMessage: "",
      userValidationMessage: "",
    };
  }

  componentDidUpdate(prevProps) {
    const { show, brokerCompanyId } = this.props;
    if (show !== prevProps.show && show) {
      this.fetchBrokerCompanyData(brokerCompanyId);
    }
  }

  fetchBrokerCompanyData = async (brokerCompanyId) => {
    if (brokerCompanyId) {
      const brokerCompanyData = await api.getBrokerCompany(brokerCompanyId);

      this.setState({
        selectedAgencyId: brokerCompanyId,
        agencyUsers: brokerCompanyData.users,
        agencyValidationMessage: "",
        userValidationMessage: "",
      });
    }
  };

  handleAgencyChange = async (e) => {
    this.fetchBrokerCompanyData(e.target.value);
  };

  handleUserChange = (e) => {
    this.setState({ selectedUserId: e.target.value });
  };

  handleAssignClick = () => {
    const { selectedUserId } = this.state;
    const { onPressSubmit } = this.props;

    if (this.state.selectedAgencyId === "") {
      this.setState({ agencyValidationMessage: "Please select an agency." });
    } else if (selectedUserId === "") {
      this.setState({ userValidationMessage: "Please select a user." });
    } else {
      onPressSubmit(selectedUserId);
    }
  };

  render() {
    const { show, onHide, agencies } = this.props;
    const {
      selectedUserId,
      agencyUsers,
      userValidationMessage,
      agencyValidationMessage,
    } = this.state;

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reassign User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formAgency" className="mb-2">
              <Form.Label>Select Agency</Form.Label>
              <Form.Select
                className="form-select-sm"
                value={this.state.selectedAgencyId}
                required
                onChange={this.handleAgencyChange}
              >
                <option value="">Select Agency</option>
                {agencies?.map((agency) => (
                  <option key={agency.id} value={agency.id}>
                    {agency.name}
                  </option>
                ))}
              </Form.Select>
              {agencyValidationMessage && (
                <Form.Text className="text-danger">
                  {agencyValidationMessage}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="formUser">
              <Form.Label>Select User</Form.Label>
              <Form.Select
                className="form-select-sm"
                value={selectedUserId}
                required
                onChange={this.handleUserChange}
              >
                <option value="">Select User</option>
                {agencyUsers?.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Form.Select>
              {userValidationMessage && (
                <Form.Text className="text-danger">
                  {userValidationMessage}
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-secondary float-right"
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-primary float-right"
            style={{ backgroundColor: "#7694aa", borderColor: "#7694aa" }}
            onClick={this.handleAssignClick}
          >
            Assign
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ReassignUserModal;
