/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import Agency from "./Agency";
import { getRecordsPerPage } from "../../constants/Pagination";
import history from "../../components/History";
import Pagination from "../../components/Pagination";
import TableSort from "../../components/TableSort";

const componentStyle = css`
  button.search,
  button.add {
    margin-top: 24px !important;
  }
`;
class Agencies extends React.Component {
  state = {
    searchTerm: "",
    searchIn: "Agency",
    totalRecords: 0,
    recordsPerPage: getRecordsPerPage(),
    totalPages: 0,
    currentPage: 1,
    orderBy: "name",
    sort: {
      BrokerCompanyName: "asc",
      AccountName: null,
      AccountEmail: null,
      AccountAdmittedProducerCode: null,
      AccountLastLoginDateTime: null,
    },
  };

  componentDidUpdate() {
    if (this.state.error) {
      setTimeout(() => {
        this.setState({ error: null });
      }, 3000);
    }
  }

  searchBrokerCompanies = async (resetSort) => {
    const { brokerCompaniesContainer } = this.props;

    if (resetSort) {
      this.sortTable("name", "asc");
    }

    const sortDirection =
      this.state.sort[this.state.orderBy] === "desc" ? true : false;

    const totalRecords = await brokerCompaniesContainer.searchBrokerCompanies(
      this.state.searchTerm,
      this.state.searchIn,
      this.state.currentPage,
      this.state.recordsPerPage,
      this.state.orderBy ? this.state.orderBy : "name",
      sortDirection
    );

    this.setState({
      totalRecords: totalRecords,
      totalPages: Math.ceil(totalRecords / this.state.recordsPerPage),
    });
  };

  sortTable = async (orderBy, direction) => {
    let tempSort = {};

    for (const key of Object.keys(this.state.sort)) {
      tempSort[key] = null;
    }

    if (!direction) {
      tempSort[orderBy] = this.state.sort[orderBy] === "asc" ? "desc" : "asc";
    } else {
      tempSort[orderBy] = direction;
    }

    await this.setState({
      orderBy: orderBy,
      sort: tempSort,
      currentPage: 1,
    });

    this.searchBrokerCompanies();
  };

  changePage = async (page) => {
    await this.setState({
      currentPage: page,
    });

    this.searchBrokerCompanies();
  };

  addAgency = async () => {
    history.push("/agencies-and-contacts/agency");
  };

  addContact = async () => {
    history.push("/agencies-and-contacts/contact");
  };

  export = async () => {
    const { brokerCompaniesContainer } = this.props;
    try {
      let result = await brokerCompaniesContainer.exportBrokerCompanies();
      const { mimeType, file, fileName } = result;

      const linkSource = `data:${mimeType};base64,${file}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (error) {
      this.setState({
        error: "There was a problem exporting the data. Please try again.",
      });
    }
  };

  deleteSelected = async () => {
    const { brokerCompaniesContainer, accountsContainer } = this.props;
    let confirm = window.confirm(
      "Are you sure you want to delete the selected agencies and/or contacts?"
    );
    if (confirm) {
      await accountsContainer.deleteSelectedAccounts();
      await brokerCompaniesContainer.deleteSelectedBrokerCompanies(
        this.state.searchTerm,
        this.state.currentPage,
        getRecordsPerPage(),
        this.state.orderBy,
        this.state.sort[this.state.orderBy] === "desc" ? true : false
      );
    }
  };

  updateValue = (e) => {
    const { name, value } = e.currentTarget;

    this.setState({
      [name]: value,
    });

    const { brokerCompaniesContainer } = this.props;

    brokerCompaniesContainer.setState({
      [name]: value,
    });
  };

  render() {
    const { brokerCompaniesContainer, accountsContainer, applicationsContainer } = this.props;

    return (
      <div css={componentStyle}>
        <div className="row margin-top">
          <div className="col">
            <h3>Agencies and Contacts</h3>
          </div>
        </div>

        <div className="row button-group">
          <div className="col-sm-3">
            <div className="form-group">
              <label htmlFor="searchTerm">Search:</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="searchTerm"
                name="searchTerm"
                onChange={this.updateValue}
                value={
                  this.state.searchTerm
                    ? this.state.searchTerm
                    : brokerCompaniesContainer.state.searchTerm
                }
                disabled={brokerCompaniesContainer.state.isLoading}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label htmlFor="searchIn">Search In:</label>
              <select
                className="form-control form-control-sm"
                id="searchIn"
                name="searchIn"
                value={this.state.searchIn}
                onChange={this.updateValue}
                required
              >
                <option value="Agency">Agency</option>
                <option value="Contact">Contact</option>
              </select>
            </div>
          </div>
          <div className="col-sm-1">
            <button
              className="btn btn-sm btn-primary search"
              onClick={() => {
                this.searchBrokerCompanies(true);
              }}
            >
              Search
            </button>
          </div>
          <div className="col-sm-6">
            <button
              className="btn btn-sm btn-primary float-right add"
              onClick={this.addContact}
            >
              Add Contact
            </button>
            <button
              className="btn btn-sm btn-primary float-right add"
              onClick={this.addAgency}
            >
              Add Agency
            </button>
            <button
              className="btn btn-sm btn-primary float-right add"
              onClick={this.export}
            >
              Export
            </button>
          </div>
        </div>
        {this.state.error && (
          <div className="row">
            <div className="col">
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            </div>
          </div>
        )}

        <div >
          <div >
            <table className="table table-sm">
              <thead>
                <tr>
                  <th colSpan="2"></th>
                  <th className="nonSort">
                    <TableSort
                      onClick={() => this.sortTable("BrokerCompanyName")}
                      direction={this.state.sort.BrokerCompanyName}
                    >
                      Agency Name
                    </TableSort>
                  </th>
                  <th colSpan="2"></th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("AccountName")}
                      direction={this.state.sort.AccountName}
                    >
                      Contact Name
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("AccountEmail")}
                      direction={this.state.sort.AccountEmail}
                    >
                      Contact Email
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() =>
                        this.sortTable("AccountAdmittedProducerCode")
                      }
                      direction={this.state.sort.AccountAdmittedProducerCode}
                    >
                      Admitted Producer Code
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("AccountLastLoginDateTime")}
                      direction={this.state.sort.AccountLastLoginDateTime}
                    >
                      Last Login Date Time
                    </TableSort>
                  </th>
                  <th colSpan="4"></th>
                  <th >Reassign</th>
                </tr>
              </thead>
              <tbody>
                {brokerCompaniesContainer.state.brokerCompaniesSearchResult.map(
                  (brokerCompany) => (
                    <Agency
                      key={brokerCompany.id}
                      brokerCompaniesContainer={brokerCompaniesContainer}
                      accountsContainer={accountsContainer}
                      applicationsContainer={applicationsContainer}
                      brokerCompany={brokerCompany}
                      searchCriteria={this.state}
                    />
                  )
                )}
                {brokerCompaniesContainer.state.brokerCompaniesSearchResult
                  .length === 0 &&
                  !brokerCompaniesContainer.state.isLoading && (
                    <tr>
                      <td colSpan="10">No Results</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>

        {brokerCompaniesContainer.state.brokerCompaniesSearchResult.length >
          0 && (
            <div className="row">
              <div className="col-sm-6">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={this.deleteSelected}
                >
                  Delete Selected
                </button>
              </div>
              {this.state.totalRecords > 0 && (
                <div className="col-sm-6">
                  <Pagination
                    totalPages={this.state.totalPages}
                    currentPage={this.state.currentPage}
                    changePage={this.changePage}
                  />
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
}

export default Agencies;
